// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qoKOPvcto5JXxCY_99uG{display:flex;align-items:center;justify-content:center;position:absolute;top:1.3438rem;right:1.3438rem;width:32px;height:32px}.qoKOPvcto5JXxCY_99uG:hover{border-radius:50%;cursor:pointer;background-color:rgba(50,50,50,.6);transition:all .3s}.qoKOPvcto5JXxCY_99uG:active{background-color:#282828}.qoKOPvcto5JXxCY_99uG:active span{background-color:#b6b6b6}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": `qoKOPvcto5JXxCY_99uG`
};
export default ___CSS_LOADER_EXPORT___;
