// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GvMvmB2VsWwREASYEx6X{position:fixed;left:0;bottom:-100%;width:100%;height:260px;background:#222;box-shadow:2px 0 5px rgba(0,0,0,.2);transition:transform .3s ease-in-out;z-index:100;padding:0px 40px 20px 42px;margin:0 auto;overflow-y:scroll}.GvMvmB2VsWwREASYEx6X .jkjr4vNyYuk3fX_zlNrA{background-color:#774dee;padding:6px 12px;border-radius:8px}.GvMvmB2VsWwREASYEx6X.G1DsL3JMIrycURdyAMco{bottom:0}.GvMvmB2VsWwREASYEx6X .ybYfr2vw3dA4UNPuPf0q{min-width:285px;height:36px !important;padding:10px}.GvMvmB2VsWwREASYEx6X .PUURe0Wu9y1OhnJmdyG5{display:flex;align-items:center;column-gap:8px}.GvMvmB2VsWwREASYEx6X .Iir_qUWQmajdUH9bCFao{font-size:18px !important;line-height:21.6px;color:#fff}.GvMvmB2VsWwREASYEx6X .GKZutqxP5B1RIm8Xs1nb{display:flex;align-items:center;justify-content:space-between;margin-bottom:12px;margin-top:12px}.GvMvmB2VsWwREASYEx6X .kyCX6k0UdV9mEkLuBc7w{display:flex;column-gap:24px;flex-wrap:wrap;row-gap:30px}.GvMvmB2VsWwREASYEx6X .kyCX6k0UdV9mEkLuBc7w .UFhJc0XZUkG2C7YF7Yhw{cursor:pointer}.GvMvmB2VsWwREASYEx6X .kyCX6k0UdV9mEkLuBc7w .UFhJc0XZUkG2C7YF7Yhw .izobV3F6DBrjDTt2nENA{margin-bottom:15px;object-fit:cover;width:206px;height:114px}.GvMvmB2VsWwREASYEx6X .kyCX6k0UdV9mEkLuBc7w .chGK2OI86d5X_6zEqX7J{display:flex;align-items:center;column-gap:15px}.GvMvmB2VsWwREASYEx6X .kyCX6k0UdV9mEkLuBc7w .E02sNrChoGMkvlRHbYwX{color:#b6b6b6}.GvMvmB2VsWwREASYEx6X .fu3sAOBWqKYMVOoq1rgx{position:sticky;width:64px;height:40px;transform:translateX(-50%);top:0px;left:50%;cursor:pointer;padding:4px 20px 4px 20px;border-radius:0px 0px 8px 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `GvMvmB2VsWwREASYEx6X`,
	"badge": `jkjr4vNyYuk3fX_zlNrA`,
	"open": `G1DsL3JMIrycURdyAMco`,
	"dropdown": `ybYfr2vw3dA4UNPuPf0q`,
	"button": `PUURe0Wu9y1OhnJmdyG5`,
	"buttonLabel": `Iir_qUWQmajdUH9bCFao`,
	"header": `GKZutqxP5B1RIm8Xs1nb`,
	"cards": `kyCX6k0UdV9mEkLuBc7w`,
	"card": `UFhJc0XZUkG2C7YF7Yhw`,
	"img": `izobV3F6DBrjDTt2nENA`,
	"body": `chGK2OI86d5X_6zEqX7J`,
	"description": `E02sNrChoGMkvlRHbYwX`,
	"closeButton": `fu3sAOBWqKYMVOoq1rgx`
};
export default ___CSS_LOADER_EXPORT___;
