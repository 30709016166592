// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m12qohGPmrc9NX27cNCG{display:flex;align-items:center;gap:8px;padding:18px 30px;border-radius:4px;background-color:rgba(50,50,50,.8);color:#fff;font-size:20px !important;border:1px solid #3d3d3d;cursor:pointer;transition:all .2s ease}.m12qohGPmrc9NX27cNCG:hover{background-color:#6439ab}.m12qohGPmrc9NX27cNCG:active{background-color:#382a50}.m12qohGPmrc9NX27cNCG .XAfGwTbXnSXh0craC126{width:24px;height:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelToggleButton": `m12qohGPmrc9NX27cNCG`,
	"icon": `XAfGwTbXnSXh0craC126`
};
export default ___CSS_LOADER_EXPORT___;
