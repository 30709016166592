// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZLOcuQWLxUCUesXFnNrq button{border-radius:50%;width:43px;height:43px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ZLOcuQWLxUCUesXFnNrq`
};
export default ___CSS_LOADER_EXPORT___;
