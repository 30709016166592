// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pynuE_S6Qc7uKnwp1k4S{background:rgba(30,30,30,.8);backdrop-filter:blur(8px);height:auto;border-radius:6px;z-index:2;position:relative;align-items:center;display:flex;column-gap:1px;padding:4px;transition:.2s ease-in-out;box-shadow:0 2px 8px rgba(0,0,0,.2)}@media(max-width: 1000px){.pynuE_S6Qc7uKnwp1k4S{transform:translateY(70px) translateX(-250px)}}.pynuE_S6Qc7uKnwp1k4S .hjSKjYVtAyqGlq0SGnl7{width:1px;height:24px;background-color:rgba(255,255,255,.2);margin:0 4px}.pynuE_S6Qc7uKnwp1k4S button{border-radius:4px;width:50px;height:30px;display:flex;justify-content:center;align-items:center;transition:background-color .2s ease}.pynuE_S6Qc7uKnwp1k4S button:hover{background-color:rgba(255,255,255,.1)}.pynuE_S6Qc7uKnwp1k4S button.QvEGARXDZJamWyDrMUlY{background-color:rgba(255,255,255,.2);border:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `pynuE_S6Qc7uKnwp1k4S`,
	"divider": `hjSKjYVtAyqGlq0SGnl7`,
	"active": `QvEGARXDZJamWyDrMUlY`
};
export default ___CSS_LOADER_EXPORT___;
