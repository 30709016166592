// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oXv_1enUdJy_ZtenZXS_{position:fixed;top:0;left:0;z-index:10;display:flex;flex-direction:column;row-gap:20px;width:100vw;height:100vh;align-items:center;justify-content:center;background-color:rgba(0,0,0,.7)}.oXv_1enUdJy_ZtenZXS_ .Kn7SvCnIt5Q8TZsmer9q{display:flex;max-width:100px;max-height:100px;padding:20px;background-color:rgba(21,21,21,.7);border-radius:50%}.oXv_1enUdJy_ZtenZXS_ .Kn7SvCnIt5Q8TZsmer9q .le_aCzm0kYzYtBuDcl6B{animation:wTM88Ew9p6Es9QLLC6hK 1s linear infinite}.oXv_1enUdJy_ZtenZXS_ .ZTVmSn93VD1gGlAj2rf5{font-size:20px;line-height:24px;font-weight:400;color:#fff}@keyframes wTM88Ew9p6Es9QLLC6hK{from{transform:rotateZ(0deg)}to{transform:rotateZ(359deg)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savingModalWrapper": `oXv_1enUdJy_ZtenZXS_`,
	"preloaderBox": `Kn7SvCnIt5Q8TZsmer9q`,
	"preloader": `le_aCzm0kYzYtBuDcl6B`,
	"rotation": `wTM88Ew9p6Es9QLLC6hK`,
	"action": `ZTVmSn93VD1gGlAj2rf5`
};
export default ___CSS_LOADER_EXPORT___;
