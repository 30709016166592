// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OxTqNFdiWKiOOBKsfOj_{position:relative}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz{display:flex;flex-direction:column;row-gap:36px;width:650px}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .E9YcAkI1PQTt0FLG8Vma{position:absolute;top:16px;left:32px;display:flex;align-items:center;column-gap:8px}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .E9YcAkI1PQTt0FLG8Vma .KvbT0f8Vrs2trRGTIjUu{background-color:#774dee;padding:6px 12px;border-radius:8px}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .E9YcAkI1PQTt0FLG8Vma>img{width:32px;height:32px}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .v2szRqRRGOhMOj5fUpXW{display:flex;flex-direction:column;row-gap:16px}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .v2szRqRRGOhMOj5fUpXW .fayNrshveISvCKPhBScN{font-size:8px !important;color:#b6b6b6}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .v2szRqRRGOhMOj5fUpXW .fRxC68rOPaoSh0rMSLD3{width:20px !important;height:20px !important}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .v2szRqRRGOhMOj5fUpXW .fRxC68rOPaoSh0rMSLD3 *{fill:#fff !important}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .v2szRqRRGOhMOj5fUpXW>div{display:flex;align-items:center}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .v2szRqRRGOhMOj5fUpXW>div>p{width:250px}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .E1R0l93Ex93Ixu45CwSw{display:flex;flex-direction:column;justify-content:center;align-items:center}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .jc9XwDbH1EOE2R0T5qbV{display:flex;justify-content:space-between}.OxTqNFdiWKiOOBKsfOj_ .vg9_rMNJWyDMfDESuvVz .jc9XwDbH1EOE2R0T5qbV .wUHtdZpsETwDxMyGxJ0_{font-size:28px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `OxTqNFdiWKiOOBKsfOj_`,
	"wrapper": `vg9_rMNJWyDMfDESuvVz`,
	"header": `E9YcAkI1PQTt0FLG8Vma`,
	"badge": `KvbT0f8Vrs2trRGTIjUu`,
	"sceneGeo": `v2szRqRRGOhMOj5fUpXW`,
	"iconGeo": `fayNrshveISvCKPhBScN`,
	"copyIcon": `fRxC68rOPaoSh0rMSLD3`,
	"qrCodeContainer": `E1R0l93Ex93Ixu45CwSw`,
	"controlButtons": `jc9XwDbH1EOE2R0T5qbV`,
	"locked": `wUHtdZpsETwDxMyGxJ0_`
};
export default ___CSS_LOADER_EXPORT___;
