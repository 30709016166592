// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F9n61ZWiyASVj99FeXCM{position:relative}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg{position:absolute;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.5);backdrop-filter:blur(2px);border-radius:12px;display:flex;align-items:center;justify-content:center;z-index:1;cursor:pointer;transition:background-color .2s ease}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg:hover{background:rgba(0,0,0,.4)}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg:active{background:rgba(0,0,0,.3)}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg .nfLPdQC7MOqcbOGxXjdA{display:flex;align-items:center;gap:8px;background:rgba(119,77,238,.9);transition:all .2s ease;padding:8px 16px;border-radius:8px;color:#fff;font-weight:500;box-shadow:0 2px 4px rgba(0,0,0,.1)}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg .nfLPdQC7MOqcbOGxXjdA:hover{background:#774dee;transform:translateY(-1px);box-shadow:0 4px 8px rgba(0,0,0,.2)}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg .nfLPdQC7MOqcbOGxXjdA:active{background:#6b45d6;transform:translateY(0);box-shadow:0 1px 2px rgba(0,0,0,.2)}.F9n61ZWiyASVj99FeXCM .r3Od3Ak_iO1xhSxebGeg .nfLPdQC7MOqcbOGxXjdA span[role=img]{font-size:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proFeaturesWrapper": `F9n61ZWiyASVj99FeXCM`,
	"proOverlay": `r3Od3Ak_iO1xhSxebGeg`,
	"proOverlayContent": `nfLPdQC7MOqcbOGxXjdA`
};
export default ___CSS_LOADER_EXPORT___;
