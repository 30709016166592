// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pynuE_S6Qc7uKnwp1k4S{position:absolute;transform:translateX(-50%);bottom:10%;left:50%;border:unset;height:auto;border-radius:16px;align-items:center;display:flex;column-gap:8px;padding:8px 12px;transition:.2s ease-in-out}.pynuE_S6Qc7uKnwp1k4S button{border-radius:50%;width:56px;height:56px;display:flex;justify-content:center;align-items:center}.pynuE_S6Qc7uKnwp1k4S button.QvEGARXDZJamWyDrMUlY{border:1px solid #fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `pynuE_S6Qc7uKnwp1k4S`,
	"active": `QvEGARXDZJamWyDrMUlY`
};
export default ___CSS_LOADER_EXPORT___;
