// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zl_LTD8yoBZu3fzebjGq{color:#fff;text-shadow:#000 0 1px 3px,#000 0 -1px 3px,#000 1px 0 3px,#000 -1px 0 3px;padding:8px 12px;border-radius:10px;cursor:pointer;position:relative;z-index:1}.zl_LTD8yoBZu3fzebjGq:hover{background:rgba(50,50,50,.4)}.zl_LTD8yoBZu3fzebjGq .r1eZBPTJtEOaU5EGOSSy{max-width:50vw;text-overflow:ellipsis;overflow:hidden;font-size:20px !important;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `zl_LTD8yoBZu3fzebjGq`,
	"title": `r1eZBPTJtEOaU5EGOSSy`
};
export default ___CSS_LOADER_EXPORT___;
