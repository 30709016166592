// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIyMzJfMjU0OCkiPgo8cGF0aCBkPSJNMTIgNVYxOU01IDEySDE5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIyMzJfMjU0OCI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIyMzJfMjU1MikiPgo8cGF0aCBkPSJNMTkgMTJMNSAxMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMjMyXzI1NTIiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNCAyNCkgcm90YXRlKC0xODApIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qPvp6m3qvA7h6PZqikWD{padding:0 !important;border-radius:50%;width:43px;height:43px}.l0HjLmNyTQvUv7MMCAEB{display:block;min-width:unset;width:43px;height:86px;overflow:hidden;border-radius:32px;margin:0;padding:0;opacity:1;visibility:visible}.l0HjLmNyTQvUv7MMCAEB .A1s0pbVPW1ebr9zd94dO{display:block;width:100%;height:50%;padding:0;margin:0;background-color:#2d2d2d;outline:unset;border:unset;border-radius:unset;color:#fff;transition:all .3s}.l0HjLmNyTQvUv7MMCAEB .A1s0pbVPW1ebr9zd94dO.WXCr5sdFnXqidFutkLNN{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:bottom 8px center}.l0HjLmNyTQvUv7MMCAEB .A1s0pbVPW1ebr9zd94dO.K3mRxScU2n_73pIGVmHH{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-repeat:no-repeat;background-position:top 8px center}.l0HjLmNyTQvUv7MMCAEB .A1s0pbVPW1ebr9zd94dO:hover{background-color:#6439df}.l0HjLmNyTQvUv7MMCAEB .A1s0pbVPW1ebr9zd94dO:active{background-color:#5b37c0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetZoomButton": `qPvp6m3qvA7h6PZqikWD`,
	"wrapper": `l0HjLmNyTQvUv7MMCAEB`,
	"btn": `A1s0pbVPW1ebr9zd94dO`,
	"plusButton": `WXCr5sdFnXqidFutkLNN`,
	"minusButton": `K3mRxScU2n_73pIGVmHH`
};
export default ___CSS_LOADER_EXPORT___;
