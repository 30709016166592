// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SIEzxOR2do7e92CZtzGA>div{display:flex;row-gap:64px}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM{display:flex;flex-direction:column;max-width:460px}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM ul{list-style-type:none;margin-top:32px}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM li{display:flex;align-items:center;column-gap:16px}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM li svg{width:24px;height:24px}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM li svg{stroke:#0eaf00;fill:#0eaf00}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM .QDfBLsVHkXFD_KLs2jch{padding:0 !important;margin-top:40px}.SIEzxOR2do7e92CZtzGA .EFtPCYd7d7qp3t6wXtmM .bntud0iEmrEYFAwORght{padding:32px !important}.SIEzxOR2do7e92CZtzGA .ITgCYSgBIgwxvgeMb9wQ{display:flex;margin:auto}.SIEzxOR2do7e92CZtzGA .ITgCYSgBIgwxvgeMb9wQ .O4PjIbm3H2uoDKNdc_1n{animation:HKMGtPEvXSag6yX_LYdd 2s linear infinite}@keyframes HKMGtPEvXSag6yX_LYdd{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk{display:flex;justify-content:center;align-items:center;position:relative;width:380px;height:350px}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk>svg{position:absolute;width:100%;height:100%}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk>svg *{stroke:#0eaf00;fill:#0eaf00}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk .HaXGRmtwM92b4RaHFrkm{position:absolute}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk .HNioeefzM7mHhKkFFsK5{width:100%;height:100%;border-radius:12px;opacity:.3}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk .QNRSzUwOkqPGxwNbRUEL{display:flex;flex-direction:column;justify-content:center;align-items:center;row-gap:16px}.SIEzxOR2do7e92CZtzGA .rOoUIcG2tW1WRkCCu3nk .QNRSzUwOkqPGxwNbRUEL>p{font-size:24px !important;font-weight:500}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `SIEzxOR2do7e92CZtzGA`,
	"paymentDetails": `EFtPCYd7d7qp3t6wXtmM`,
	"learnMoreBtn": `QDfBLsVHkXFD_KLs2jch`,
	"paymentButton": `bntud0iEmrEYFAwORght`,
	"loadingSpinner": `ITgCYSgBIgwxvgeMb9wQ`,
	"spinner": `O4PjIbm3H2uoDKNdc_1n`,
	"spin": `HKMGtPEvXSag6yX_LYdd`,
	"rightCol": `rOoUIcG2tW1WRkCCu3nk`,
	"checkoutWithBtn": `HaXGRmtwM92b4RaHFrkm`,
	"paymentPlaceholder": `HNioeefzM7mHhKkFFsK5`,
	"unAuth": `QNRSzUwOkqPGxwNbRUEL`
};
export default ___CSS_LOADER_EXPORT___;
