// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IFqEb3Ya8EmptqoWLqss{border:unset;border-radius:50%;width:43px;height:43px;background-color:#774dee;padding:0 !important}.IFqEb3Ya8EmptqoWLqss:hover{background-color:#6439df}.IFqEb3Ya8EmptqoWLqss:active{background-color:#5b37c0}.gs2e1i5egMufxJqLIIgX{width:43px;height:43px;border-radius:18px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileButton": `IFqEb3Ya8EmptqoWLqss`,
	"photoURL": `gs2e1i5egMufxJqLIIgX`
};
export default ___CSS_LOADER_EXPORT___;
