// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j6qam1lQOW3_vPAu_OeS{padding:8px 12px;border:none;background-color:#2c2c2c;color:#fff;border-radius:4px;cursor:pointer;font-size:14px;transition:background-color .2s}.j6qam1lQOW3_vPAu_OeS:hover{background-color:#3c3c3c}.j6qam1lQOW3_vPAu_OeS:active{background-color:#1c1c1c}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unitsToggle": `j6qam1lQOW3_vPAu_OeS`
};
export default ___CSS_LOADER_EXPORT___;
