// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zJoz2pGXkivf0sWURjCL{position:absolute;display:flex;top:36px;left:40px;z-index:10;display:flex;width:fit-content;align-items:center;column-gap:1.5rem}.DXIrbRprn20NI2MhhFut img{width:48px;height:48px;object-fit:contain}@media screen and (max-width: 1268px){.zJoz2pGXkivf0sWURjCL{column-gap:1.5rem;row-gap:12px;left:20px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `zJoz2pGXkivf0sWURjCL`,
	"logo": `DXIrbRprn20NI2MhhFut`
};
export default ___CSS_LOADER_EXPORT___;
